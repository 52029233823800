<template>
  <PItem phase-final-reward>
    <h2><em>본선 보상</em></h2>
    <dl class="final">
      <dt>
        <span class="dia-point">총 상금 <b class="y">4,500만원</b></span>
      </dt>
      <dd>
        <h4>
          <span>
            <img src="/img/pages/shotVal/landing/pc/tier_Gold.png" />
            <b class="ft">GOLD</b> <em>골드 스테이지 보상</em></span>
        </h4>
        <p class="reward">
          <span>1위<b class="y">200만원</b></span>
          <span>2위<b class="y">100만원</b></span>
          <span>공동 3위<b class="y">50만원</b></span>
        </p>
      </dd>
      <dd>
        <h4>
          <span>
            <img src="/img/pages/shotVal/landing/pc/tier_Diamond.png" />
            <b class="ft">DIAMOND</b> <em>다이아 스테이지 보상</em></span>
        </h4>
        <p class="reward">
          <span>1위<b class="y">250만원</b></span>
          <span>2위<b class="y">150만원</b></span>
          <span>공동 3위<b class="y">50만원</b></span>
        </p>
      </dd>
      <dd>
        <h4>
          <span>
            <img src="/img/pages/shotVal/landing/pc/tier_Radiant.png" />
            <b class="ft">RADIANT</b> <em>레디언트 스테이지 보상</em></span>
        </h4>
        <p class="reward">
          <span>1위<b class="y">1,300만원</b></span>
          <span>2위<b class="y">600만원</b></span>
          <span>공동 3위<b class="y">400만원</b></span>
        </p>
        <p class="group-stage"> 5 ~ 8위 <b>100만원</b> / 9 ~ 16위 <b>50만원</b></p>
      </dd>
      <dd>
        <h4>
          <span><b class="ft">MVP</b> <em>상금</em> <b class="y">100만원</b></span>
        </h4>
      </dd>
    </dl>

    <dl class="special">
      <dt class="sm">
        <span class="dia-point sm"><b>특별 보상</b></span>
      </dt>
      <dd>
        <div class="rewards">
          <img src="/img/pages/shotVal/home/specialReward1.png" />
          <img src="/img/pages/shotVal/home/specialReward2.png" />
        </div>
        <em class="y">각 스테이지 본선 진출 시</em><br /> <b>발로란트 1주년 기념 굿즈 &<br />한미 마이크로닉스 게이밍 기어세트 획득!</b>
      </dd>
    </dl>
  </PItem>
</template>

<script>
export default {
  name: 'PhaseFinalReward',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[phase-final-reward] { .tc;
  .intro-up(1, {
    h2 { .val-randing-move; }
  });
  .intro-up(2, {
    dl.final { .val-randing-move; }
  });
  .intro-up(9, {
    dl.special { .val-randing-move; }
  });
  h2 { .val-randing-base;}
  dl { .p(40, 14, 0); .mb(20); .-box; .val-randing-base;
    dt { .mb(20); }
    &.final { .wh(100%, 736); .contain('@{val-landing}/frame2-1_bg.svg');
      dd { .w(100%); .p(20, 0); .bgc(rgba(0, 0, 0, .5)); .-a(rgba(251, 41, 53, .5), 2px); .rel; .mt(12);
        &:before, &:after {.cnt; .wh(8, 70); .bgc(#fb2935); .abs; .t(50%); .t-yc;}
        &:before { .l(0);}
        &:after { .r(0);}
        &:nth-of-type(1) { .mt(0); }
        &:nth-of-type(2) { .-a(rgba(102, 86, 250, .5), 2px);
          &:before, &:after { .bgc(#6656fa); }
        }
        &:nth-of-type(3) { .-a(rgba(93, 208, 81, .5), 2px);
          &:before, &:after { .bgc(#5dd051); }
        }
        &:nth-of-type(4) { .-a(rgba(93, 208, 81, .5), 2px);
          &:before, &:after { content: none; }
          h4 {
            > span {
              &:before, &:after { .w(28); .ib;}
            }
            .ft { .fs(30);}
            b {.fs(22);}
          }
        }

        > .reward { .ib-list; .mt(18);
          > span { .fs(14); .ls(-1);
            &:nth-of-type(2) { .m(0, 24); .rel;
              &:before, &:after { .cnt; .wh(4); .bgc(white); .t-r(45deg); .abs; .t(6);}
              &:before { .l(-14); }
              &:after { .r(-14); }
            }
            > b { .block; .fs(20); .ls(-2); .mt(8);}
          }
        }
        > .group-stage { .fs(12); .ls(-1); .o(.5); .mt(8);
          em { .ib; .mr(8); }
        }
      }
    }
    &.special { .wh(100%, 606); .cnt; .contain('@{val-landing}/frame2-2_bg.svg'); .mb(0);
      dd {
        img { .block; .wh(100%, 172); .m(20, 0, 16); }
        em { .regular; .ls(0);}
        b { .ls(0); .block; .mt(4); }
      }
    }

    h4 { .flex-middle;
      span {.ib; .ib-list;
        &:before, &:after {.cnt; .ib; .wh(16, 4); background: linear-gradient(90deg, white 33.333%, transparent 0); background: -webkit-linear-gradient(0deg, red 50%, green 0); background: -moz-linear-gradient(0deg, red 50%, green 0); .bg-s(12, 4); .m(0, 8); .hide;}
        > * { .vam; }
        img { .wh(38); }
        b { .fs(30); .m(0, 6, 0, 4);}
        em {.fs(16); .regular;}
      }
    }
  }

  @media (@tl-up) {
    dl { .p(60, 44, 0);
      dt { .mb(32);}
      &.final { .contain('@{val-landing}/t/frame2-1_bg.svg'); .h(874); .mb(60);
        dd { .p(30, 0); .mt(18);
          &:before, &:after { .wh(12, 82); }
          &:nth-of-type(3) { .p(24, 0); }
          &:nth-of-type(4) {
            h4 {
              span {
                .ft { .fs(42); }
                b { .fs(36); }
                em { .fs(26); }
              }
            }
          }

          > .reward { .mt(12);
            > span { .fs(20, 32px);
              b { .fs(32); .ls(-2); .ib; .vam; .mt(0); .ml(11); .ls(-1); }
              &:nth-of-type(2) { .m(0, 54);
                &:before { .lt(-26, 14); }
                &:after { .rt(-26, 14); }
              }
            }
          }
          > .group-stage { .fs(16); .mt(14); }
        }
      }
      &.special { .h(614); .contain('@{val-landing}/t/frame2-2_bg.svg');
        dd {
          .rewards { .max-w(820);
            > img { .ib;
              &:nth-of-type(1) { .mr(10);}
            }
          }
          img { .wh(400, 238); .m(32, 0, 30); }
          em { .fs(24); }
          b { .fs(28); .mt(8); }
        }
      }
      h4 {
        span {
          &:before, &:after { .wh(42, 6) !important; .bg-s(18, 6); .vam; .m(0, 20); .ib;}
          img { .wh(52); }
          em { .fs(20); .ls(-2); }
          b { .fs(36); .m(0, 10, 0, 8);}
        }
      }
    }
  }

  @media (@ds-up) {
    dl { .p(60, 80, 0);
      &.final { .wh(980, 874); .contain('@{val-landing}/pc/frame2-1_bg.svg'); .mh-c;}
      &.special { .wh(980, 614); .contain('@{val-landing}/pc/frame2-2_bg.svg'); .mh-c;}
    }
  }
}
</style>
