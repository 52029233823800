<template>
  <PSection phase-reward-section id="basic-reward">
    <div class="list-holder">
      <PhaseBaseReward />
      <PhaseFinalReward />
    </div>
    <img src="/img/pages/shotVal/landing/pc/cha_01.png" class="character" v-prx:progress="{ r: [5, 8], tx: [100, 0], o: [0, 1] }" />
  </PSection>
</template>

<script>
import PhaseBaseReward from '@/views/components/landing/shotVal/reward/PhaseBaseReward.vue';
import PhaseFinalReward from '@/views/components/landing/shotVal/reward/PhaseFinalReward.vue';

export default {
  name: 'PhaseRewardSection',
  components: { PhaseFinalReward, PhaseBaseReward },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[phase-reward-section] { .bg('@{val-landing}/02_bg.png'); .bg-s(1024, 3095); .bg-xc; .bg-y(0); .rel; .mt(-40); .z(2); .no-repeat; .p(100, 0);
  dl {
    dt { .fs(28, 60px); .ls(-1); .h(60); .bgc(rgba(0, 0, 0, .8)); .rel; .-v(#fff);}
  }
  @media (@tl-up) {
    .bg('@{val-landing}/pc/02_bg.png'); .bg-s(1920, 3120); .bg-y(-40); .mt(-60); .p(190, 0, 150);
    dl {
      dt { .fs(36, 70px); .h(70); .w(442); .mh-c;
        &.sm { .fs(32, 60px); .h(60); }
      }
    }
    .character { .lb(50%, 168); .wh(417, 877); .ml(-780); }
  }
  @media (@ds-up) {
    .pb(270);

  }
}
</style>
