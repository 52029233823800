<template>
  <PSection key-visual-section id="main-visual">
    <div class="list-holder lg">
      <p class="symbol"><em /></p>
      <h1>
        총 상금 <b class="y">4,500만원</b>을 건 <br class="hidden-tv-up" />전국 발로란트 유저들의 <b class="y">대축제!</b>
      </h1>
      <dl>
        <dt>접수기간</dt>
        <dd><b>골드/다이아</b><em>10월4일(월) ~ 10월14일(목)</em></dd>
        <dd><b>레디언트</b><em>10월4일(월) ~ 10월21일(목)</em></dd>
      </dl>
      <dl>
        <dt>대회기간</dt>
        <dd><b>골드/다이아</b><em>10월16일(토) ~ 10월28일(목)</em></dd>
        <dd><b>레디언트</b><em>10월23일(토) ~ 11월16일(화)</em></dd>
      </dl>
      <button class="btn-go" @click="goMain" />
    </div>
    <p class="btn-down">
      <button @click="toNextSection">Scroll Down</button>
    </p>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'KeyVisualSection',
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    toNextSection() {
      const secondSection = this.$el.nextSibling;
      const targetScrollOffset = secondSection.offsetTop - (document.querySelector('header').clientHeight || 0);

      this.$scroll.scrollMove(targetScrollOffset, 1000);
    },
    goMain() {
      this.trackEvent(`landing_challenge_01`, 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/val');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[key-visual-section] { .cover('@{val-landing}/key-visual.jpg'); .bg-xc; .h(100vh); .min-h(900); .-box; .c(white); .tc; .rel;
  .intro-up(1, {
    .list-holder.lg {
      .symbol {
        animation-duration: .8s;
        animation-name: elastic-snap-side;
        > em {
          animation-delay: 1.8s;
          .jello;
        }
      }
      h1, dl, .btn-go { .t-y(0); .o(1); }
    }
    .btn-down { .o(1); }
  });
  .list-holder.lg { .pt(10); .abs; .lt(50%, 50%); .t-xyc; .max-w(404);
    .symbol { .wh(100%, 194); .block; transition: transform 1s; .mh-c; .mb(8);
      > em {.wh(100%);.bg('@{logo}/VA_Final_logo.png'); .bg-s(404, 194); .no-repeat; .bg-xc; .block; }
    }
    h1 { .fs(24, 32px); .ls(-5%); .regular; .mb(32); .w(100%); .bgc(#090909); .p(7, 0); .t-y(50); .o(0); .tr-to(.7s, .9s);}
    dl { .p(16, 0); .tl; .bgc(rgba(0, 0, 0, .6)); .-a(rgba(255, 255, 255, .3)); .rel; .mb(8); .mh-c; .max-w(335); .tr-to(.7s, 1.2s); .t-y(50); .o(0);
      &:nth-of-type(2) dt { .contain('@{val-landing}/schedule_red.svg'); }
      dt { .abs; .bold; .tc; .wh(80, 30); .fs(14, 30px); .lt(-4, 50%); .t-yc; .contain('@{val-landing}/schedule_black.svg'); }
      dd { .pl(95); .fs(12); .mt(12); .font-spoqa; .ls(0);
        &:nth-of-type(1) { .mt(0);}
        em { .o(.8); .ml(8); }
      }
    }
    .btn-go { .tr-o(.7s, 1.5s); .o(0); }
  }
  .btn-down { animation: wr-bounce 1.5s infinite; .tr-to(.7s, 2.5s); .o(0); .abs; .b(100); .tc; .w(100%);
    > button { .c(white); .fs(12); .ls(0); .ib;
      &:after { .cnt; .wh(24, 12); .contain('@{val-landing}/icon_scroll_down_m.svg'); .mh-c; .mt(8);}
    }
  }
  @media (@tl-up) {
    .min-h(1000);
    .list-holder.lg { .pt(0); .max-w(908);
      .symbol { .wh(686, 330); max-width: none;
        > em { .bg-s(100%, 100%);}
      }
      h1 { .wh(908, 48); .mh-c; .contain('@{val-landing}/t/sub_title.svg'); .bgc(transparent); .fs(32, 56px); .ls(-2); .p(0); .mb(20); }
      dl { .w(446); max-width: none; .ib;
        &:nth-of-type(1) { .mr(16); }
        dt { .l(18); .wh(86, 32); .fs(16); }
        dd { .pl(133); .fs(16);
          em { .ml(12); }
        }
      }
    }
    .btn-down { .b(90);
      > button { .fs(16);
        &:after { .wh(30, 15); .contain('@{val-landing}/pc/icon_scroll_down.svg'); .mt(10);}
      }
    }
  }
  @media (@ds-up) {
    .min-h(1200);
    .list-holder.lg { .max-w(1010);
      h1 { .wh(100%, 54); .contain('@{val-landing}/pc/sub_title.svg'); .fs(38, 58px); .mb(42); }
      dl { .w(460);
        dt { .l(34); }
        dd { .pl(140); }
      }
    }
  }

  @keyframes elastic-snap-side {
    from { opacity: 0; transform: translate3d(-100%, 0, 0); }
    60% { opacity: 1; transform: translate3d(30%, 0, 0); }
    to { opacity: 1; transform: translate3d(-0, 0, 0); }
  }
}
</style>
