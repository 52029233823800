<template>
  <div shot-val-landing>
    <KeyVisualSection />
    <PhaseRewardSection />
    <StageRuleSection />
    <InvitationTeamSection />
    <CommercialMovieSection />
    <ParticipationRuleSection />
    <PSection class="quick-menu-holder">
      <QuickMenu v-prx:fixed
        v-if="quickMenu.menus.length"
        :button="quickMenu.button"
        :menus="quickMenu.menus"
        @button-click="discordConnect"
        :init-page="initPage"
        :init-sub-page="initSubPage"
      />
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import KeyVisualSection from '@/views/components/landing/shotVal/KeyVisualSection.vue';
import PhaseRewardSection from '@/views/components/landing/shotVal/PhaseRewardSection.vue';
import StageRuleSection from '@/views/components/landing/shotVal/StageRuleSection.vue';
import InvitationTeamSection from '@/views/components/landing/shotVal/InvitationTeamSection.vue';
import CommercialMovieSection from '@/views/components/landing/shotVal/CommercialMovieSection.vue';
import QuickMenu from '@/views/components/landing/QuickMenu.vue';
import ParticipationRuleSection from '@/views/components/landing/shotVal/ParticipationRuleSection.vue';
import meta from '@/data/meta';

export default {
  name: 'ShotValLanding',
  mixins: [gtag],
  components: { ParticipationRuleSection, QuickMenu, CommercialMovieSection, InvitationTeamSection, StageRuleSection, PhaseRewardSection, KeyVisualSection },
  data() {
    return {
      quickMenu: {
        button: {
          img: '/img/pages/icon/discord.svg',
          text: '팀원 찾기',
        },
        menus: [
          {
            domId: 'main-visual',
            name: '대회 소개',
          },
          {
            domId: 'basic-reward',
            name: '대회 보상',
          },
          {
            domId: 'proceed-way',
            name: '대회 방식',
          },
          {
            domId: 'invitation-team',
            name: '인플루언서 매치',
          },
          {
            domId: 'official-video',
            name: '홍보 영상',
          },
          {
            domId: 'participation-rules',
            name: '참고 사항',
          },
        ],
      },
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    initPage() {
      const q = this.$route.query.page;
      return q === 'radiant' || q === 'gold' ? 3 : -1;
    },
    initSubPage() {
      const q = this.$route.query.page;
      return q === 'radiant' ? 'RADIANT' : null;
    },
    size() {
      return this.$store.state.browser.matchedMedia;
    },
    initTerm() {
      const s = this.size?.slice(0, 1);
      const q = this.$route.query.page;
      return q === 'radiant' ? (s === 'M' ? 2600 : s === 'T' ? 700 : 2000) : 0;
    },
  },
  methods: {
    discordConnect() {
      this.trackEvent('landing_discord_btn', 'click', `${this.upRouteGameId}`);
      window.open('https://discord.gg/PkpEJxM98K');
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
  mounted() {
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-landing] { .c(white); .bgc(black); .font-g; .ls(-1);
  .quick-menu-holder { .fix; .z(10);}
  > [p-section] { .rel; }
  .y { .c(#ffc700); }
  h2 { .wh(100%, 52); .mh-c; .tc; background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 51.04%, rgba(0, 0, 0, 0) 100%); .rel; .mb(30);
    &:before, &:after { .cnt; .wh(100%, 1); background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 51.04%, rgba(255, 255, 255, 0) 100%); .o(0.3); .abs; .lt(0, 0);}
    &:after { .lb(0, 0);}
    > * { .ib; .fs(28, 52px);
      &:before, &:after { .cnt; .ib; .wh(6, 36);.vam; .mb(4);}
      &:before { .contain('@{val-landing}/title-l.svg'); .mr(20);}
      &:after { .contain('@{val-landing}/title-r.svg'); .ml(20); }
    }
  }
  .list-holder { .w(100%); .max-w(335); .mh-c; .rel;
    .btn-go { .wh(300, 53); .contain('@{val-landing}/main_btn.svg'); .bg-xc; .mh-c; .mt(34); .block;}
    .dia-point {
      &:before, &:after { .cnt; .ib; .wh(6); .t-r(45deg); .bgc(white); .vam; .m(0, 12);}
      &.sm {
        &:before, &:after { .wh(4); }
      }
    }
  }
  .character {.abs; .hide;}
  @media (@tl-up) {
    h2 { .h(80); .max-w(100%); .mb(70);
      > * { .fs(40, 80px);
        &:before, &:after { .wh(133, 58); }
        &:before { .contain('@{val-landing}/pc/h1_left.svg'); .mr(40);}
        &:after { .contain('@{val-landing}/pc/h1_right.svg'); .ml(40); }
      }
    }
    .list-holder { .max-w(910);
      .btn-go { .wh(428, 76); .mt(67); .rel; .mh-c;
        &:before { .cnt; .wh(156, 20); .contain('@{val-landing}/pc/main_btn_left.svg'); .lt(50%, 50%); .ml(-412); .t-yc; .abs;}
        &:after { .cnt; .wh(156, 20); .contain('@{val-landing}/pc/main_btn_right.svg'); .rt(50%, 50%); .mr(-412); .t-yc; .abs;}
      }
    }
    .character { .block; pointer-events: none;}
  }

  @media (@ds-up) {
    h2 { .w(980); .mb(82); }
    .list-holder { .max-w(1280);

    }
  }
}
</style>
