<template>
  <PSection participation-rule-section theme="white" id="participation-rules">
    <div class="context">
      <h4>참여 시 꼭 확인하세요.</h4>
      <div class="detail">
        <em>대회 일정 참고사항</em>
        <ul>
          <li>대회 기간: 10월 16일(토)부터 11월 16일(화)까지 진행</li>
          <li>참가 신청 오픈: 10월 4일(월) 오전 11시</li>
          <li>로스터 변경: 참가 신청 마감 이전까지 수정 가능</li>
          <div class="stage">골드 스테이지</div>
          <ul>
            <li>예선: 10월 16일(토), 10월 17일(일) 오후 4시 - 총 2일</li>
            <li>본선: 10월 20일(수), 10월 21일(목) 오후 6시 – 총 2일</li>
          </ul>
          <div class="stage">다이아 스테이지</div>
          <ul>
            <li>예선: 10월 16일(토), 10월 17일(일) 오후 4시 - 총 2일</li>
            <li>본선: 10월 27일(수), 10월 28일(목) 오후 6시 – 총 2일</li>
          </ul>
          <div class="stage">레디언트 스테이지</div>
          <ul>
            <li>예선: 10월 23일(토), 10월 24일(일) 오후 4시 - 총 2일</li>
            <li :class="['line-etc','tab3']">본선 16강 그룹 스테이지: 11월 1일(월)
              <div class="tab1">~ 11월 4일(목) 오후 6시 – 총 4일</div>
            </li>
            <li>본선 8강: 11월 10일(수), 11월 11일(목) 오후 6시 – 총 2일</li>
            <li>본선 4강~결승: 11월 15일(월), 11월 16일(화) 오후 6시 – 총 2일</li>
          </ul>
        </ul>
        <em>예선 진행 시 참고사항</em>
        <ul>
          <li>경기 장소: 전 경기 온라인 대회 진행</li>
          <li>경기 방식: 토너먼트 (단판제)</li>
          <li>경기 로스터: 최대 7인(참가 선수 5인, 후보 선수 2인)</li>
          <li>체크인: 대회 시작 1시간 전부터 50분 간 진행</li>
          <li>대진표 공개: 체크인 마감 후 10분 내 대진표 생성 후 공개</li>
          <li>매칭 기준: 레벨업 페이지 대진표 기능을 통한 랜덤 매칭</li>
          <li>경기 진영: 랜덤 배정으로 레벨업 페이지 내 대진표 상세에서 확인 가능</li>
          <li>경기 설정: 발로란트 ‘서울1’ 서버에서 진행</li>
          <li>경기 진행
            <ul>
              <li class="tab">대진표 확인 후 대진표 상 상단에 위치한 팀이 커스텀 매치를 <br />개설합니다.</li>
              <li class="tab">커스텀 매치 개설 후 대진표 하단에 위치한 팀을 초대하여 규정에 따라 <br />경기를 진행합니다.</li>
              <li>로스터 외 선수 참여 시 자동 탈락됩니다.</li>
            </ul>
          </li>
          <li>경기 종료 후 결과 입력
            <ul>
              <li>경기 종료 후 승리팀은 결과 스크린샷을 사이트에 업로드합니다.</li>
              <li class="tab">패배팀은 상대 결과 스크린샷을 확인하고 결과를 승인합니다. <br />(운영진이 최종 확인할 예정입니다.)</li>
            </ul>
          </li>
        </ul>
        <em>본선 진행 시 참고사항</em>
        <ul>
          <li> 경기 방식: 스테이지 별 토너먼트</li>
          <li>경기 로스터: 최대 7인(참가 선수 5인, 후보 선수 2인)</li>
          <li>매칭 기준: 대진표에 따른 매칭</li>
          <li>경기 설정: 발로란트 ‘서울1’ 서버에서 진행</li>
          <li class="line-etc">경기 장소: 레디언트 스테이지 4강 및 결승을 제외한
            <div class="next-line" />
            전 경기 온라인 대회 진행, 방송 진행
          </li>
          <li class="line-etc">레디언트 스테이지 4강 및 결승: 오프라인 대회 진행
            <div class="tab1">(레벨업 레드 스튜디오), 방송 진행</div>
            <div>(코로나 19 거리두기 단계에 따라 온라인으로 진행될 수 있습니다.)
            </div>
          </li>
        </ul>
        <em class="reward">보상 및 혜택 주의사항</em>
        <ul>
          <li>상금: 제세공과금은 수상자가 부담</li>
          <li class="line-etc">매운맛 총기 장식 & 에피소드2 포메이션 플레이어 카드:
            <div class="next-line">대회 참여 계정으로 인게임 지급(10월 4주차 지급 예정)</div>
          </li>
          <li>발로란트 마우스 장패드: 11월 2~3주차 지급 예정</li>
          <li class="line-etc">발로란트 1주년 굿즈(티셔츠, 후드집업, 볼캡, 암슬리브):
            <div class="next-line">11월 2~3주차 지급 예정</div>
          </li>
          <li class="line-etc">마이크로닉스 게이밍 기어 세트(키보드, 마우스, 헤드셋, 장패드):
            <div class="next-line">11월 1~2주차 지급 예정</div>
          </li>
          <li :class="['line-etc', 'tab2']">마이크로닉스 게이밍 기어 세트는 아래 명시된 제품 지급 예정 <br />MECHA ZK-1 카일박스 RGB 게이밍 기계식 키보드 <br />(갈축, 적축, 백축 중 지급) <br />MECHA ZM-1 RGB 게이밍 마우스 <br />MANIC HS-420 7.1CH 노이즈 캔슬링 마이크 헤드셋 <br />(Pink, White, Black 중 지급) <br />MANIC Gaming Field P1 장패드
          </li>
        </ul>
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'ParticipationRuleSection',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[participation-rule-section] { .rel; .bgc(#1a1d22); .min-h(500);
  .context { .rel; z-index: 2; .max-w(1280); .mh-c; .pt(60); .pb(120);
    > h4 { .c(white); .fs(20, 20px); .o(.8);.min-w(196); .font-g; .tc;
      &:after { .cnt; width: calc(100% - 20px); .h(1.92); .bgc(white); .o(.1); .mt(20); .mb(18); .ml(10);}
    }
    .detail { .max-w(336); .tl; .mh-c; .font-spoqa;
      .stage { .mt(8);}
      > em {.c(white); .o(.6); .fs(16, 32px); .block; .mt(16); .mb(6); .bold;}
      > ul { .pl(8); .fs(11, 21px);
        > div { .bold; .o(.6); .ml(-5);}
        > ul {
          li { .o(.4); list-style: '- '; }
        }
        .line-etc { .lh(16);}
        .next-line { .ml(-5);}
        > li { .regular; .o(.4); .c(white); list-style: '- ';
          > ul { .pl(10);
            > li { .fs(11, 16px); list-style: decimal; }
          }
        }
      }
    }
    &:after {.cnt; width: calc(100% - 20px); .h(1.92); .bgc(white); .o(.1); .mt(24); .ml(10); }
  }
  @media (@tl-up) {
    .context { .pt(120); .pb(200);
      > h4 { .fs(32, 41.6px);
        &:after { width: calc(100% - 116px); .m(40, 0); .ml(58);}
      }
      .detail { .max-w(780);
        .stage { .mt(12);}
        > em { .fs(24, 32px); .mt(40); .mb(12);}
        > ul { .pl(12); .fs(16, 32px);
          .line-etc { .lh(24);
            &:nth-of-type(2) { .lh(32);
              &.tab3 { .lh(24);}
            }
            &:nth-of-type(3) { .lh(32);}
            &:nth-of-type(4) { .lh(32);}
            &:nth-of-type(5) { .lh(32);}
          }
          .next-line { .ib; .ml(0);}
          .tab {
            > br {
              &:nth-of-type(1) { .hide;}
            }
          }
          .tab1 { .ib;}
          .tab2 {
            > br {
              &:nth-of-type(2) { .hide;}
              &:nth-of-type(5) { .hide;}
            }
          }
          > div { .fs(16, 32px); .ml(5);}
          > ul {
            li { .fs(16, 24px); .ml(15);}
            .tab1 { .ib;}
          }
          > li {
            > ul {
              > li { .fs(16, 24px);
              }
            }
          }
        }
        &:after { .mt(40); .ml(58); width: calc(100% - 116px); }
      }
    }
  }
  @media (@ds-up) {
    .context {
      > h4 { .w(100%); .min-w(1080);
        &:after { width: calc(100%); .m(40, 0); }
      }
      .detail { .max-w(873);}
      &:after { .mt(40); .ml(58); width: calc(100%); }
    }
  }
}

</style>
