<template>
  <PSection stage-rule-section id="proceed-way">
    <div class="list-holder">
      <h2><em>스테이지 별 경기 진행 방식</em></h2>
      <article class="match">
        <h3>인플루언서 이벤트 매치<em class="y">10월 18일(월) 18:00</em></h3>
        <template v-if="!show">
          <div class="not-show" />
        </template>
        <template v-else>
          <div class="teams">
            <div class="team-holder">
              <div class="team">
                <div class="img-holder">
                  <img class="img" src="/img/pages/shotVal/landing/influencer/letsgo.jpg" />
                </div>
                <h4 class="dia-point">
                  <span>레.스.고</span>
                </h4>
              </div>
            </div>
            <p class="ft">VS</p>
            <div class="team-holder">
              <div class="team">
                <div class="img-holder">
                  <img class="img" src="/img/pages/shotVal/landing/influencer/move.jpg" />
                </div>
                <h4 class="dia-point">
                  <span>이사가자</span>
                </h4>
              </div>
            </div>
          </div>
        </template>
      </article>
      <ul class="grade">
        <li v-for="(item,id) in gradeMap" :key="id" :class="item.grade">
          <h4>
            <img :src="item.badge" />
            <b class="ft mb4">{{ item.grade }}</b><em class="mt2">{{ item.gradeKo }}<br />스테이지</em>
          </h4>
          <ol>
            <li>
              <label />
              <p>
                <em v-html="item.round1.msg.t" /> <b>{{ item.round1.msg.b }}</b>
              </p>
            </li>
            <li>
              <label class="pt2">예선 일정</label>
              <p v-for="(date,dId) in item.round1.schedule" :key="dId"><b>{{ date.name }}</b><em>{{ date.date }}</em>
              </p>
            </li>
          </ol>
          <ol>
            <li>
              <label />
              <p>
                <em v-html="item.round2.msg.t" /> <b>{{ item.round2.msg.b }}</b>
              </p>
            </li>
            <li>
              <label class="pt2">본선 일정</label>
              <p v-for="(date,dId) in item.round2.schedule" :key="dId"><b>{{ date.name }}</b><em>{{ date.date }}</em>
              </p>
            </li>
          </ol>
        </li>
      </ul>
    </div>
    <img src="/img/pages/shotVal/landing/pc/cha_02.png" class="character"
      v-prx:progress="{ r: [3, 6], tx: [-100, 0], o: [0, 1] }" />
  </PSection>
</template>

<script>
export default {
  name: 'StageRuleSection',
  data() {
    return {
      gradeMap: [
        {
          grade: 'GOLD',
          gradeKo: '골드',
          badge: '/img/pages/shotVal/landing/pc/tier_Gold.png',
          round1: {
            msg: { t: '일자 별 2개 그룹 토너먼트를 진행(Bo1)', b: '상위 2팀(총 4팀) 스테이지 본선 진출' },
            schedule: [
              { name: '1차', date: '10월 16일 (토)' },
              { name: '2차', date: '10월 17일 (일)' },
            ],
          },
          round2: {
            msg: { t: '예선 상위 4팀 + 인플루언서 4팀', b: '총 8팀, 생방송 8강 토너먼트 진행' },
            schedule: [
              { name: '8강', date: '10월 20일 (수)' },
              { name: '4강/결승', date: '10월 21일 (목)' },
            ],
          },
        },
        {
          grade: 'DIAMOND',
          gradeKo: '다이아',
          badge: '/img/pages/shotVal/landing/pc/tier_Diamond.png',
          round1: {
            msg: { t: '일자 별 3개 그룹 토너먼트를 진행(Bo1)', b: '상위 3팀(총 6팀) 스테이지 본선 진출' },
            schedule: [
              { name: '1차', date: '10월 16일 (토)' },
              { name: '2차', date: '10월 17일 (일)' },
            ],
          },
          round2: {
            msg: { t: '예선 상위 6팀 + 인플루언서 2팀', b: '총 8팀, 생방송 8강 토너먼트 진행' },
            schedule: [
              { name: '8강', date: '10월 27일 (수)' },
              { name: '4강/결승', date: '10월 28일 (목)' },
            ],
          },
        },
        {
          grade: 'RADIANT',
          gradeKo: '레디언트',
          badge: '/img/pages/shotVal/landing/pc/tier_Radiant.png',
          round1: {
            msg: { t: '일자 별 5개 그룹 토너먼트를 진행(Bo1)', b: '상위 5팀(총 10팀) 스테이지 본선 진출' },
            schedule: [
              { name: '1차', date: '10월 23일 (토)' },
              { name: '2차', date: '10월 24일 (일)' },
            ],
          },
          round2: {
            msg: { t: '예선 상위 10팀 + 인플루언서 2팀 <br/>+ 챌린저스 프로 4팀', b: '총 16팀, 생방송 8강 토너먼트 진행' },
            schedule: [
              { name: '그룹스테이지', date: '11월 1일 (월) ~ 4일 (목)' },
              { name: '8강', date: '11월 10일 (수) ~ 11일 (목)' },
              { name: '4강', date: '11월 15일 (월)' },
              { name: '결승', date: '11월 16일 (화)' },
            ],
          },
        },
      ],
    };
  },
  computed: {
    show() {
      return new Date().getTime() >= new Date('2021-10-18').getTime();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[stage-rule-section] { .p(80, 0); .bg('@{val-landing}/03_bg.png'); .no-repeat; .bg-s(1024, 2798); .bg-xc; .mt(-40); .rel; .z(3);
  .intro-up(2, {
    .list-holder h2 { .val-randing-move; }
  });
  .intro-up(3, {
    .match { .val-randing-move; }
  });
  .progress-up(2, {
    .grade { .val-randing-move; }
  });

  h2 { .val-randing-base;
    > em { .fs(20);}
  }
  .match { .tc; .wh(100%, 736); .contain('@{val-landing}/frame3-1_bg.svg'); .p(40, 16, 0); .-box; .mb(40); .val-randing-base;
    .not-show { .wh(100%); .bg-c; .bg('@{val-landing}/ComingSoon_375.png'); .no-repeat; .mt(-115);}
    h3 { .fs(24); .p(16, 0); .bgc(#090909); .mb(20);
      em { .fs(18); .block; .regular;}
    }
    .teams {
      .team-holder { position: static;
        &:nth-of-type(1) { .tl;}
        &:nth-of-type(2) { .tr;}
        .team { .ib;
          .img-holder { .contain('@{val-landing}/frame3-1_img_bg_mobile.svg'); .wh(249, 187); .p(2); .pb(10); .mb(12);
            .img { .wh(245, 175);}
          }
          > h4 { .wh(100%, 36); .bgc(#d42332); .lh(36); .fs(20); .tc;
            &:before, &:after { .m(0, 8);}
          }
        }
      }
      > p { .ib; .vam; .c(#d42332); .fs(30); .m(0, 6); .m(20, 0); }
    }

  }
  .grade { .val-randing-base;
    > li { .wh(100%, 524); .contain('@{val-landing}/frame3-2_bg.svg'); .mt(40); .p(0, 20);
      &:nth-of-type(1) { .mt(0); }
      &:nth-of-type(2) { .contain('@{val-landing}/frame3-3_bg.svg');
        > ol > li:nth-of-type(2) label { .bgc(#4f46b8); }
      }
      &:nth-of-type(3) { .wh(100%, 598); .contain('@{val-landing}/frame3-4_bg.svg');
        > ol > li:nth-of-type(2) label { .bgc(#52b54e); }
      }
      > h4 { .ib-list; .pl(2); .pt(4); .mb(32);
        > * { .vam; }
        img { .wh(40); }
        b { .fs(44, 40px); .m(4, 6, 0, 12); }
        em { .fs (16, 16px); .medium; }
      }
      > ol {
        &:nth-of-type(1) { .mb(40); .rel;
          &:after { .cnt; .wh(0); .-b(transparent, 20); .-l(transparent, 10); .-r(transparent, 10); .-t(rgba(255, 255, 255, .4), 10); .abs; .lb(27, -44); .wh(20, 10); .-box; .crop;}
        }
        &:nth-of-type(2) > li:nth-of-type(1) label { .contain('@{val-landing}/title1-1.svg') !important; }

        > li {
          > p { .medium; }
          &:nth-of-type(1) { .mb(16);
            label { .wh(82, 38); .contain('@{val-landing}/title2-1.svg'); .mb(12); .block; }
            > p { .ls(0);
              b { .block; .mt(6);}
            }
          }
          &:nth-of-type(2) {
            label { .wh(64, 20); .bgc(#d42332); .block; .fs(14, 20px); .ls(-2); .medium; .tc; .mb(10); }
            > p { .mt(6); .ls(0); .fs(16);
              &:nth-of-type(1) { .mt(0); }
              b { .ib;
                &:after { .cnt; .ib; .wh(1, 16); .vam; .m(0, 12, 2); .bgc(white); .o(.5);}
              }
              em { .medium; }
            }
          }
        }
      }
    }
  }

  @media (@tl-up) {
    .bg('@{val-landing}/pc/03_bg.png'); .bg-s(1920, 3120); .mt(-60); .bg-y(-40);  .p(170, 0, 150);
    h2 > em { .fs(42); }
    .match { .wh(910, 622); .contain('@{val-landing}/t/frame3-1_bg.svg'); .pt(60);
      .not-show { .wh(100%); .bg-c; .bg('@{val-landing}/pc/ComingSoon_1920.png'); .no-repeat; .mt(-115);}
      h3 { .w(442); .mh-c; .p(20, 0); .fs(36); .mb(40);
        em {.fs(24); .mt(8); }
      }
      .teams { .rel; .m(0, 45);
        .team-holder { .abs;
          &:nth-of-type(1) { .lt(0, 0);}
          &:nth-of-type(2) { .rt(0, 0); .tl;}
          .team {
            .img-holder { .contain('@{val-landing}/frame3-1_img_bg.svg'); .wh(350, 262); .mb(40);
              .img { .w(100%); height: calc(100% - 5px);}

            }
            > h4 { .wh(100%, 60); .bgc(#d42332); .lh(60); .fs(32); .tc; .m(0);
              &:before, &:after { .m(0, 8);}
            }
          }
        }
        > p { .ib; .fs(80); .lh(270); }
      }
    }
    .grade {
      > li { .wh(100%, 478); .contain('@{val-landing}/t/frame3-2_bg.svg'); .mt(70); .p(0, 30);
        &:nth-of-type(2) { .contain('@{val-landing}/t/frame3-3_bg.svg'); }
        &:nth-of-type(3) { .contain('@{val-landing}/t/frame3-4_bg.svg'); .h(548);
          > ol {
            > li {
              &:nth-of-type(1) { .w(374); .mr(30);}
            }
          }
        }

        > h4 { .pl(6); .pt(8); .mb(46);
          img { .wh(52);}
          b { .fs(52); .m(0, 7, 0, 20);}
          em { .fs(20, 20px); }
        }
        > ol { white-space: nowrap;
          &:nth-of-type(1) { .mb(50);
            &:after { .lb(60, -50);}
          }
          &:nth-of-type(2) > li:nth-of-type(1) label { .contain('@{val-landing}/t/title1-1.svg') !important; }

          > li { .ib; .vat;
            &:nth-of-type(1) { .w(384); .mr(40); .mb(0);
              label { .wh(142, 52); .contain('@{val-landing}/t/title2-1.svg') }
              > p { .fs(20); .rel;
                b { .mt(12); }
                &:before { .cnt; .wh(1, 100%); .bgc(white); .o(.2); .abs; .rt(0, 0);}
              }
            }
            &:nth-of-type(2) { .rel; .pt(65); .pl(110);
              label { .wh(94, 26); .fs(20, 26px); .ib; .abs; .lt(0, 65);}
              > p { .fs(20); .mt(12);
                em { .ls(-1);}
              }
            }
          }
        }
      }
    }
    .character { .rt(50%, 1280); .wh(343, 769); .mr(-752); }
  }

  @media (@ds-up) {
    .pb(200);
    .match {.contain('@{val-landing}/t/frame3-1_bg.svg'); .wh(980, 712); .mh-c;
      .not-show { .mt(-135);}
    }
    .grade { .w(1180); .mh-c;
      > li { .wh(100%, 420); .contain('@{val-landing}/pc/frame3-2_bg.svg'); .mt(80); .p(0);
        &:nth-of-type(2) { .contain('@{val-landing}/pc/frame3-3_bg.svg'); }
        &:nth-of-type(3) { .contain('@{val-landing}/pc/frame3-4_bg.svg'); .h(478);
          > ol {
            > li {
              &:nth-of-type(1) { .mr(40);}
            }
            &:nth-of-type(2) {
              > li { .vat; }
            }
          }
        }

        > h4 { .pt(38); }
        > ol { .pl(100);
          &:nth-of-type(1) {
            &:after { .lb(130, -52);}
          }
          &:nth-of-type(2) > li:nth-of-type(1) label { .contain('@{val-landing}/pc/title1-1.svg') !important; }

          > li { .vam;
            &:nth-of-type(1) { .rel; width: auto !important;
              label { .wh(82); .mb(0); .mr(40); .contain('@{val-landing}/pc/title2-1.svg'); .ib; .vat;}
              > p { .ib; .vam; .pr(40); .w(384); .-box;}
            }
            &:nth-of-type(2) { .pt(0); .vat;
              label { .lt(0, 0); .lh(27);}
              > p { .mt(15);}
            }
          }
        }
      }
    }
  }
}
</style>
