<template>
  <PSection invitation-team-section id="invitation-team">
    <div class="list-holder">
      <h2><em>초청팀 리스트</em></h2>
      <PItem v-for="(item,id) in gradeMap" :key="id" :class="['ama',item.grade]">
        <h3>
          <img :src="item.badge" />
          <b class="ft">{{ item.grade }} STAGE</b><em class="hidden-tv-down">{{
            item.gradeKo
          }}</em></h3>
        <template v-if="item.view">
          <div v-for="(team,tId) in item.teams" class="no-empty" :key="tId">
            <h5>Team. {{ team.teamName }}</h5>
            <span v-for="(member,mId) in team.member" :key="mId">
              <img :src="`${item.imgRoot}${member.img}`" />
              <label>{{ member.name }}</label>
            </span>
          </div>
        </template>
        <template v-else>
          <div class="empty" />
        </template>
      </PItem>
      <div class="pro">
        <h3>
          <img :src="proTeam.badge" />
          <b class="ft">{{ proTeam.grade }}</b><em
          class="hidden-tv-down">{{ proTeam.gradeKo }}</em></h3>
        <template v-if="proTeam.view">
          <span v-for="(item,id) in proTeam.member" :key="id">
            <img :src="`${proTeam.imgRoot}${item.img}`" />
            <label>{{ item.name }}</label>
          </span>
        </template>
        <template v-else>
          <div class="empty"></div>
        </template>
      </div>
    </div>
    <img src="/img/pages/shotVal/landing/pc/cha_03.png" class="character c1"
      v-prx:intro="{ r: [4, 8], tx: [-100, 0], o: [0, 1] }" />
    <img src="/img/pages/shotVal/landing/pc/cha_04.png" class="character c2"
      v-prx:progress="{ r: [6, 9], tx: [100, 0], o: [0, 1] }" />
  </PSection>
</template>

<script>
export default {
  name: 'InvitationTeamSection',
  data() {
    return {
      open1: false,
      open2: false,
      gradeMap: [
        {
          startDate: '2021-09-01',
          grade: 'GOLD',
          gradeKo: '골드 스테이지',
          badge: '/img/pages/shotVal/landing/pc/tier_Gold.png',
          imgRoot: '/img/pages/shotVal/landing/group/gold/',
          teams: [
            {
              teamName: '늪지대',
              member: [{ name: '악어', img: 'team1/1.png' }, { name: '남봉', img: 'team1/2.png' }, { name: '리타', img: 'team1/3.png' }, { name: '중력', img: 'team1/4.png' }, { name: '핑맨', img: 'team1/5.png' }],
            },
            {
              teamName: 'NO:OB',
              member: [{ name: '새옴', img: 'team2/1.png' }, { name: '수담', img: 'team2/2.png' }, { name: '이치오', img: 'team2/3.png' }, { name: '망개', img: 'team2/4.png' }, { name: '서강수', img: 'team2/5.png' }],
            },
            {
              teamName: '처음초롬',
              member: [{ name: '새초롬', img: 'team3/1.png' }, { name: '메도우이헌터', img: 'team3/2.png' }, { name: '치킨쿤', img: 'team3/3.png' }, { name: '빛나람', img: 'team3/4.png' }, { name: '꽃감이', img: 'team3/5.png' }],
            },
            {
              teamName: '오합zl존',
              member: [{ name: '이녕', img: 'team4/1.png' }, { name: '나무늘보', img: 'team4/2.png' }, { name: '삐라', img: 'team4/3.png' }, { name: '시심이', img: 'team4/4.png' }, { name: '토리몬', img: 'team4/5.png' }],
            },
          ],
        },

        {
          startDate: '2021-09-01',
          grade: 'DIAMOND',
          gradeKo: '다이아 스테이지',
          badge: '/img/pages/shotVal/landing/pc/tier_Diamond.png',
          imgRoot: '/img/pages/shotVal/landing/group/diamond/',
          teams: [
            {
              teamName: 'G9',
              member: [{ name: '미라클', img: 'team1/1.png' }, { name: '딩셉션', img: 'team1/2.png' }, { name: '석티비', img: 'team1/3.png' }, { name: '유레카', img: 'team1/4.png' }, { name: '토끼예나', img: 'team1/5.png' }],
            },
            {
              teamName: '우승시 제로투',
              member: [{ name: '심술', img: 'team2/1.png' }, { name: '쿠챠', img: 'team2/2.png' }, { name: '콕끼리', img: 'team2/3.png' }, { name: '너불', img: 'team2/4.png' }, { name: '김아카', img: 'team2/5.png' }],
            },
          ],
        },
        {
          startDate: '2021-10-18',
          grade: 'RADIANT',
          gradeKo: '레디언트 스테이지',
          badge: '/img/pages/shotVal/landing/pc/tier_Radiant.png',
          imgRoot: '/img/pages/shotVal/landing/group/radiant/',
          teams: [
            {
              teamName: '레.스.고',
              member: [{ name: '류제홍', img: 'team1/1.png' }, { name: '김된모', img: 'team1/2.png' }, { name: '문기도', img: 'team1/3.png' }, { name: '플라워', img: 'team1/4.png' }, { name: 'xzi', img: 'team1/5.png' }],
            },
            {
              teamName: '이사가자',
              member: [{ name: '푸린', img: 'team2/1.png' }, { name: '갓데드', img: 'team2/2.png' }, { name: '선데이', img: 'team2/3.png' }, { name: '설담', img: 'team2/4.png' }, { name: '제스트', img: 'team2/5.png' }],
            },
          ],
        },
      ],
      proTeam: {
        startDate: '2021-10-18',
        grade: 'RADIANT STAGE PRO TEAM',
        gradeKo: '레디언트 스테이지 프로팀',
        badge: '/img/pages/shotVal/landing/pc/tier_Radiant.png',
        imgRoot: '/img/pages/shotVal/landing/group/radiant/',
        member: [{ name: 'SPEAR Gaming', img: 'pro/1.png' }, { name: 'TNL eSports', img: 'pro/2.png' }, { name: 'World Game Star', img: 'pro/3.png' }, { name: 'Alpha Six Gaming', img: 'pro/4.png' }],
      },
    };
  },
  computed: {},
  mounted() {
    this.gradeMap = this.gradeMap.map(item => this.setVisibleItem(item));
    this.proTeam = this.setVisibleItem(this.proTeam);
  },
  methods: {
    setVisibleItem(item) {
      const todayTimeStamp = new Date().getTime();
      const startTimeStamp = new Date(item.startDate).getTime();

      if (todayTimeStamp >= startTimeStamp) item.view = true;

      return item;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[invitation-team-section] { .p(80, 0, 100); .bg('@{val-landing}/04_bg.png'); .no-repeat; .bg-s(1024, 4868); .bg-xc; .mt(-40); .rel; .z(4);
  .intro-up(2, {
    .list-holder h2 { .val-randing-move; }
  });
  .intro-up(3, {
    .list-holder > div:nth-of-type(1) { .val-randing-move; }
  });
  .progress-up(2, {
    .list-holder > div:nth-of-type(2) { .val-randing-move; }
  });
  .progress-up(4, {
    .list-holder > div:nth-of-type(3) { .val-randing-move; }
  });
  .progress-up(7, {
    .list-holder > div:nth-of-type(4) { .val-randing-move; }
  });
  .list-holder {
    h2 { .mb(40); .val-randing-base;}
    > div { .wh(100%, 1517); .contain('@{val-landing}/m_frame4-1_bg.svg'); .p(60, 20, 0); .-box; .mt(35); .val-randing-base;
      .no-empty { .mt(0);}
      .empty { .wh(100%); .mt(-100); .bg-c; .bg('@{val-landing}/ComingSoon_w_375.png'); .no-repeat;}
      h5 {
        &:before {.cnt; .wh(20, 14); .ib; .vam; .mr(4);}
      }
      &.GOLD {
        h5 {
          &:before { .contain('@{val-landing}/team_bullet_gold.svg'); }
        }
      }
      &.DIAMOND {
        h5 {
          &:before { .contain('@{val-landing}/team_bullet_diamond.svg');}
        }
      }
      &.RADIANT {
        h5 {
          &:before { .contain('@{val-landing}/team_bullet_radiant.svg');}
        }
      }
      &:nth-of-type(2) { .contain('@{val-landing}/m_frame4-2_bg.svg'); .h(851);
        h3 { .contain('@{val-landing}/m_frame4-2_h2.svg'); }
        > div > span { .contain('@{val-landing}/m_frame4-2_profile.svg'); }
      }
      &:nth-of-type(3) { .contain('@{val-landing}/m_frame4-3_bg.svg'); .h(851);
        h3 { .contain('@{val-landing}/m_frame4-3_h2.svg'); }
        > div > span { .contain('@{val-landing}/m_frame4-3_profile.svg'); }
      }
      &:nth-of-type(4) { .contain('@{val-landing}/m_frame4-4_bg.svg'); .h(659);
        h3 { .contain('@{val-landing}/m_frame4-3_h2.svg'); .fs(30, 40px);}
        > span { .wh(140, 222); .contain('@{val-landing}/m_frame4-4_profile.svg'); .ib; .p(1, 1); .vat;
          &:nth-of-type(odd) { .mr(15); .mb(16);}
          img { .wh(138, 150);}
          label { .flex-center; .h(42); .fs(13); .ls(0); }
        }
      }

      h3 { .wh(100%, 57); .contain('@{val-landing}/m_frame4-1_h2.svg'); .mb(40); .pl(4); .pt(4); .fs(40, 40px);
        img { .wh(48); }
        b { .vam; .mt(4); .ib; .ml(12);}
      }

      > div { .mt(4);
        h5 { .c(#302809); .fs(16); .mb(16); .mt(10);
        }
        > span { .wh(86, 131); .ib; .contain('@{val-landing}/m_frame4-1_profile.svg'); .mr(18); .mb(16); .p(1, 1);
          &:nth-of-type(3n) { .mr(0); }
          img { .wh(84, 105); .mh-c; .block; }
          label { .wh(100%, 26); .flex-center; .fs(12); .ls(0); }
        }
      }
    }
  }
  @media (@tl-up) {
    .bg('@{val-landing}/pc/04_bg.png'); .bg-s(1920, 3691);  .mt(-60); .bg-y(-40);  .p(130, 0, 150);
    .list-holder { .z(2);
      h2 { .mb(80); }
      > div { .h(1010); .contain('@{val-landing}/t/frame4-1_bg.svg'); .mt(80); .pt(70);
        .no-empty { .mt(0);}
        .empty { .wh(100%); .mt(-100); .bg-c; .bg('@{val-landing}/pc/ComingSoon_w_1920.png');}
        &:nth-of-type(2) { .h(610); .contain('@{val-landing}/t/frame4-2_bg.svg');
          h3 { .contain('@{val-landing}/pc/frame4-2_h2.svg'); .wh(496, 64);}
        }
        &:nth-of-type(3) { .h(610); .contain('@{val-landing}/t/frame4-3_bg.svg');
          h3 { .contain('@{val-landing}/pc/frame4-3_h2.svg'); .wh(496, 64);}
        }
        &:nth-of-type(4) { .h(500); .contain('@{val-landing}/t/frame4-4_bg.svg'); .tc;
          h3 { .contain('@{val-landing}/pc/frame4-4_h2.svg'); .wh(723, 64); .tl; .mb(50); }
          > span { .wh(180, 250); .contain('@{val-landing}/pc/frame4-4_profile.svg'); .ml(20);
            &:nth-of-type(1) { .ml(0); }
            &:nth-of-type(odd) { .mr(0); .mb(0);}
            img { .wh(178, 192);}
            label { .wh(100%, 58); .fs(18, 58px);}
          }
        }

        h3 { .wh(409, 64); .contain('@{val-landing}/pc/frame4-1_h2.svg'); .mh-c; .pl(6); .pt(6); .ib-list; .mb(36);
          > * { .vam;}
          img { .wh(52); }
          b { .ib; .m(6, 8, 0, 20); .fs(52); }
          em { .fs(20, 20px); .regular; .mt(20); .ib; }
        }
        > div { .mt(20); .w(560); .mh-c;
          h5 { .fs(20); .mb(8); .mt(20);}
          > span { .mb(0); .mr(0); .ml(20); .wh(96, 148);
            &:nth-of-type(1) { .ml(0);}
            img { .wh(94, 117); }
            label { .h(28); .fs(14); .ls(-.7); }
          }
        }
      }
    }
    .character.c1 {.z(1); .wh(588, 478); .rt(50%, 80); .mr(-936);}
    .character.c2 {.z(3); .wh(642, 502); .lb(50%, 328); .ml(-720);}
  }

  @media (@ds-up) {
    .pb(200);
    .list-holder {
      > div { .wh(980, 1010); .contain('@{val-landing}/pc/frame4-1_bg.svg'); .mh-c;
        &:nth-of-type(2) { .contain('@{val-landing}/pc/frame4-2_bg.svg'); .wh(980, 610); }
        &:nth-of-type(3) { .contain('@{val-landing}/pc/frame4-3_bg.svg'); .wh(980, 610); }
        &:nth-of-type(4) { .contain('@{val-landing}/pc/frame4-4_bg.svg'); .wh(980, 530);
          > span { .wh(200, 277);
            img {.wh(196, 213); }
            label { .fs(18, 62); .h(62);}
          }
        }
      }
    }
    .character.c1 {.rt(50%, 80); .mr(-936);}
    .character.c2 {.lb(50%, 418); .ml(-756);}
  }
}
</style>
