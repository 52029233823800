<template>
  <PItem phase-base-reward>
    <h2><em>단계별 기본 보상</em></h2>
    <ul>
      <li>
        <label>1단계</label>
        <h3>대회 참가 신청</h3>
        <img class="rewardImg1" :src="'/img/pages/shotVal/landing/img_reward1.png'" />
        <p>
          <b>매운맛 총기 장식 증정!</b><br /> <em class="y">참가자 전원에게 증정!</em>
        </p>
      </li>
      <li>
        <label>2단계</label>
        <h3>팀 구성 및 최초 경기 진행</h3>
        <img class="rewardImg2" :src="'/img/pages/shotVal/landing/img_reward2.png'" />
        <p>
          <b>에피소드 2 포메이션<br />플레이어 카드 증정</b>
        </p>
      </li>
      <li>
        <label>3단계</label>
        <h3>1승 달성</h3>
        <img class="rewardImg3" :src="'/img/pages/shotVal/landing/img_reward3.png'" />
        <p>
          <b>발로란트 마우스 <br />장패드 증정</b>
        </p>
      </li>
    </ul>
  </PItem>
</template>

<script>
export default {
  name: 'PhaseBaseReward',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[phase-base-reward] { .pb(60);
  .intro-up(1, {
    h2 { .val-randing-move; }
  });
  .intro-up(2, {
    > ul { .val-randing-move; }
  });
  h2 { .val-randing-base; }
  > ul { .w(272); .mh-c; .val-randing-base;
    > li { .wh(100%, 344); .contain('@{val-landing}/frame1-1_bg.svg'); .mt(42); .rel;
      &:nth-of-type(2) { .contain('@{val-landing}/frame1-2_bg.svg');
        &:before { .cnt; .abs; .wh(24, 12); .contain('@{val-landing}/arrow (2).svg'); .lt(123, 363);}
      }
      &:nth-of-type(3) { .contain('@{val-landing}/frame1-3_bg.svg'); }
      &:nth-of-type(1) { .mt(0);
        &:before { .cnt; .abs; .wh(24, 12); .contain('@{val-landing}/arrow (2).svg'); .lt(123, 363);}
      }
      .rewardImg1 {.wh(224, 136); .abs; .lt(25, 101);}
      .rewardImg2 { .wh(213, 130); .abs; .lt(27, 100);}
      .rewardImg3 { .wh(215, 130); .abs; .lt(25, 100);}
      label { .abs; .lt(16, 4); .c(#ff4655); .fs(18); .regular;}
      h3 { .abs; .lt(16, 40);}
      p { .abs; .w(100%); .tc; .b(30); .font-spoqa; .bold;}
    }
  }
  @media (@tl-up) {
    .pb(120);
    > ul {.w(100%); .mt(72);
      > li { .wh(274, 386); .ib; .mt(0);
        &:nth-of-type(1) { .contain('@{val-landing}/t/frame1-1_bg.svg');
          &:before { .contain('@{val-landing}/arrow (1).svg'); .wh(12, 24); .lt(287, 178);}
        }
        &:nth-of-type(2) { .contain('@{val-landing}/t/frame1-2_bg.svg'); .m(0, 35);
          &:before { .contain('@{val-landing}/arrow (1).svg'); .wh(12, 24); .lt(287, 178);}
        }
        &:nth-of-type(3) { .contain('@{val-landing}/t/frame1-3_bg.svg'); }
        .rewardImg1 { .wh(224, 136); .lt(25, 130);}
        .rewardImg2 { .wh(213, 130); .lt(27, 131);}
        .rewardImg3 { .wh(215, 130); .lt(25, 128);}
        label { .lt(16, 32);}
        h3 { .lt(16, 68); }
        p { .b(38); .fs(22);
          em { .fs(16); }
        }
      }
    }
  }
  @media (@ds-up) {
    .pb(140);
    > ul { .tc;
      > li { .wh(320, 500); .tl;
        &:nth-of-type(1) { .contain('@{val-landing}/pc/frame1-1_bg.svg');
          &:before { .contain('@{val-landing}/arrow.svg'); .wh(56, 20); .lt(340, 234);}
        }
        &:nth-of-type(2) { .contain('@{val-landing}/pc/frame1-2_bg.svg'); .m(0, 104);
          &:before { .contain('@{val-landing}/arrow.svg'); .wh(56, 20); .lt(340, 234);}
          h3 {.fs(24); .ls(-1.5);}
        }
        &:nth-of-type(3) { .contain('@{val-landing}/pc/frame1-3_bg.svg'); }
        .rewardImg1 { .wh(260, 158); .lt(26, 163);}
        .rewardImg2 { .wh(250, 152); .lt(32, 165);}
        .rewardImg3 { .wh(257, 150); .lt(28, 163);}
        label { .lt(22, 40); .fs(20); }
        h3 { .lt(22, 76); .fs(28); }
        p { .b(74); .fs(24); .tl; .pl(34);
          em { .fs(16); }
        }
      }
    }
  }
}
</style>
