<template>
  <PSection commercial-movie-section id="official-video">
    <div class="list-holder">
      <h2><em>홍보 영상</em></h2>
      <div class="mov">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/ARYx0sMHXOg?autoplay=1&amp;loop=1&amp;mute=1&playlist=ARYx0sMHXOg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          controls="0"
          allowfullscreen
        ></iframe>
      </div>
      <button class="btn-go" @click="goMain" />
    </div>
    <img src="/img/pages/shotVal/landing/pc/cha_05.png" class="character c1"
      v-prx:intro="{ r: [3, 6], tx: [-150, 0], o: [0, 1] }" />
    <img src="/img/pages/shotVal/landing/pc/cha_06.png" class="character c2"
      v-prx:intro="{ r: [5, 8], tx: [150, 0], o: [0, 1] }" />
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'CommercialMovieSection',
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goMain() {
      this.trackEvent(`landing_challenge_02`, 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/val');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[commercial-movie-section] { .p(90, 0, 60); .bg('@{val-landing}/05_bg.png'); .no-repeat; .bg-s(1024, 1121); .bg-xc; .mt(-40); .rel; .z(5);
  .intro-up(2, {
    .list-holder h2 { .val-randing-move; }
  });
  .intro-up(3, {
    .mov { .val-randing-move;
    }
  });

  h2 { .val-randing-base;}
  .mov { .wh(100%, 210); .mh-c; .val-randing-base;
    > img { .wh(100%);}
    h4 { .abs; .lt(50%, 50%); .t-xyc; .fs(16); }
  }

  @media (@tl-up) {
    .bg('@{val-landing}/pc/05_bg.png'); .bg-s(1920, 1258); .bg-y(-40); .mt(-60); .p(140, 0, 120);
    .mov { .wh(100%, 540); .rel;
      &:before { .cnt; .abs; .lt(0, 0); .wh(0); .-r(transpaent, 50); .-b(transparent, 50); .-l(#1c2733, 50); .-t(#1c2733, 0); .z(1);}
      > img { .wh(100%); }
      h4 { .fs(32); }
    }
    .btn-go { .mt(102); }
    .character.c1 {.wh(283, 724); .rt(50%, -56); .mr(-742);}
    .character.c2 {.wh(375, 542); .lb(50%, 0); .ml(-880);}
  }
  @media (@ds-up) {
    .p(160, 0, 140);
    h2 { .mb(90) !important; }
    .mov { .wh(960, 540); .mh-c;
    }
  }
}
</style>
